import '../index.css';

function Button({children, disabled, onClick, small, white, light}) {
  return (
    <button 
      onClick={onClick} 
      disabled={disabled} 
      className={'text-sm sm:text-base transition-all duration-250 border border-1 rounded-full uppercase font-medium disabled:bg-ac-disabled disabled:border-none disabled:cursor-default ' 
      + (small ? 'px-10 py-1 ' : 'px-10 py-2 md:px-12 ')
      + (white ? 'text-ac-dark-green border-white bg-white hover:bg-white/20 hover:border-white/10 hover:text-white' : 'text-white border-ac-dark-green bg-ac-dark-green hover:bg-white/80 hover:border-white enabled:hover:text-ac-dark-green')
    }>
      {children}
    </button>
  );
}

export default Button;
