import '../index.css';
import Background from '../images/atma-cena-bg.jpg'

function Container({children}) {
  return (
    <div className="w-screen min-h-screen py-4 border border-4 border-ac-dark-green flex justify-center items-center flex-col font-montserrat bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${Background})` }}>
        {children}
    </div>
  );
}

export default Container;
