import Container from '../components/Container';
import CustomLink from '../components/CustomLink';
import '../index.css';

function Disclaimer() {
  return (
    <Container>
        <div className="py-16 px-8 w-5/6 max-w-3xl flex flex-col gap-8 bg-white/90 rounded-xl">
          <h1 className='self-center text-center text-lg'>Disclaimer</h1>
          <hr />
          <div className="p-4 md:p-8 self-center">
            <p className='text-center'>This self-assessment quiz is provided for informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment.<br/>Any results provided are not a definitive diagnosis, and any decisions based on them should be discussed with a qualified healthcare professional. If you are experiencing mental health concerns, seek the guidance of a licensed professional who can provide personalized support and appropriate interventions.</p><br />
            <p className='text-center italic font-bold'>In case of a crisis or emergency, please contact your local emergency services or a healthcare provider immediately. </p>
          </div>
          <hr />
          <CustomLink to="/psychedelics-and-the-mental-health-continuum">✔ I understand</CustomLink>
        </div>
    </Container>
  );
}

export default Disclaimer;
