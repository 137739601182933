import '../index.css';

function Question({question, scores, handleSelect, currentSelection}) {
  return (
    <div className="w-full h-full flex justify-center items-center flex-col font-montserrat px-1">
        <h3 className='rounded-lg capitalize text-base sm:text-md md:text-md text-center w-4/5'>{question}</h3>
        {question.subtext && <p>{question.subtext}</p>}
        <div className='flex gap-3 lg:gap-5 flex-wrap justify-center mt-10'>
            {scores.map(score => {
                return <div key={score.label} className="question-selector cursor-pointer flex items-center justify-center rounded-full w-1/2 md:w-52 lg:w-60 min-w-44 h-fit border border-1 border-ac-dark-green bg-white hover:bg-ac-dark-green hover:text-white">
                    <label htmlFor={score.label} className='w-full h-full py-2 text-center cursor-pointer text-sm sm:text-base'>{score.label}</label>
                    <input onChange={(e) => handleSelect(e)} checked={score.value === currentSelection?.value} type="radio" id={score.label} value={score.value} name="selection" className='hidden'></input>
                </div>
            })}
        </div>
    </div>
  );
}

export default Question;
